/* Reset global margins and paddings */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Garante que padding e bordas não extrapolem o tamanho dos elementos */
}

body {
    overflow-x: hidden; /* Remove scroll horizontal */
    font-family: Arial, sans-serif; /* Fonte padrão */
}

.product-page {
    background-color: rgb(221, 221, 221); /* Cor de fundo */
    width: 100%; /* Garante que não ultrapasse a largura da tela */
    padding: 2rem 0; /* Espaçamento superior e inferior */
}

.product-list-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    width: 100%; /* Garante que ocupe toda a largura do contêiner */
}

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Layout fluido e centralizado */
    gap: 1.5rem; /* Espaçamento entre os itens */
    width: 100%; /* Garante que a lista ocupe toda a largura disponível */
    justify-content: center; /* Centraliza os itens na tela */
    padding: 0 2rem; /* Espaçamento interno nas laterais */
}

.product-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #f0f0f0; /* Cor de fundo dos itens */
    border-radius: 8px; /* Bordas arredondadas */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra leve */
    overflow: hidden; /* Evita que o conteúdo interno extrapole */
    transition: transform 0.2s ease, box-shadow 0.2s ease; /* Efeito de hover */
}

.product-item:hover {
    transform: scale(1.05); /* Aumenta levemente o item ao passar o mouse */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Intensifica a sombra */
}

.product-image {
    width: 100%; /* Largura total do item */
    height: auto; /* Altura dinâmica para manter a proporção */
    max-height: 180px; /* Altura máxima das imagens */
    object-fit: contain; /* Mantém a proporção da imagem sem cortá-la */
    background-color: #ffffff; /* Fundo branco para destacar a imagem */
}

.product-name {
    font-size: 1rem; /* Tamanho da fonte */
    font-weight: bold; /* Negrito */
    margin: 1rem 0; /* Espaçamento superior e inferior */
    text-align: center;
    color: #333; /* Cor do texto */
}

.category-title {
    margin: 2rem 0 1rem 2rem; /* Espaçamento em volta do título */
    font-size: 1.5rem; /* Tamanho do título */
    color: #333; /* Cor do texto */
    font-weight: bold; /* Negrito */
}

/* Responsividade */
@media (max-width: 768px) {
    .product-list {
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Ajusta o grid para telas menores */
        gap: 1.2rem; /* Reduz o espaçamento */
        padding: 0 1rem; /* Reduz o padding */
    }

    .category-title {
        font-size: 1.3rem; /* Diminui o tamanho do título */
        margin: 1.5rem 0 1rem 1rem; /* Ajusta os espaçamentos */
    }

    .product-item {
        padding: 1rem; /* Adiciona espaçamento interno */
        max-width: 300px; /* Limita a largura dos produtos */
    }

    .product-image {
        max-height: 220px; /* Ajusta a altura da imagem */
    }

    .product-name {
        font-size: 1.1rem; /* Aumenta o tamanho da fonte */
    }
}

@media (max-width: 480px) {
    .product-list {
        grid-template-columns: 1fr; /* Exibe uma única coluna em telas muito pequenas */
        justify-items: center; /* Garante centralização dos itens */
        padding: 0 1rem; /* Reduz o padding */
    }

    .product-item {
        max-width: 100%; /* Torna os produtos maiores em celulares */
        padding: 1.5rem; /* Aumenta o espaçamento interno */
    }

    .product-image {
        max-height: 250px; /* Ajusta a altura da imagem */
    }

    .product-name {
        font-size: 1.2rem; /* Ajusta o tamanho da fonte */
    }
}
