/* src/pages/ProductListPage.css */
.product-list-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: gray;
}

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, 220px);
    justify-content: start;
    gap: 2.5rem;
    width: 100%;
}

.product-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: white;
    border-radius: 3%;
    height: 100%;
}

.product-image {
    width: 100%;
    height: 180px;
    object-fit: contain;
    border-radius: 8px;
}

.product-name {
    font-size: 1.1rem;
    font-weight: bold;
    margin-top: 1rem;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}