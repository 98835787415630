/* Estilo geral do header */
header {
    background-color: #0072C6;
    padding: 10px 20px;
  }
  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  
  .logo a {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .logo-image {
    height: 50px; /* Ajuste o tamanho conforme necessário */
    width: auto;  /* Mantém a proporção da imagem */
  }
  
  
  /* Botão para abrir o menu no celular */
  .menu-toggle {
    display: none;
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  /* Menu principal */
  nav.menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  ul {
    align-items: center;
  }
  nav.menu ul {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
  }
  
  nav.menu ul li {
    margin: 0;
  }
  
  nav.menu ul a {
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
  }
  
  nav.menu ul a:hover {
    text-decoration: underline;
  }
  
  /* Estilo dos botões */
  .logout-button, .login-button {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
  }
  
  .logout-button:hover, .login-button:hover {
    background-color: #fff;
    color: #0072C6;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .menu-toggle {
      display: block;
    }
  
    nav.menu {
      display: none;
      flex-direction: column;
      position: fixed;
      top: 0;
      right: 0;
      width: 250px;
      height: 100%;
      background-color: #0072C6;
      box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
      padding: 20px;
      z-index: 1000;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
    }
  
    nav.menu.open {
      display: flex;
      transform: translateX(0);
    }
  
    nav.menu ul {
      flex-direction: column;
      gap: 15px;
    }
  
    nav.menu ul a {
      font-size: 1.2rem;
    }
  
    /* Backdrop */
    .backdrop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 999;
    }
  }